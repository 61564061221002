#taboola-below-homepage-thumbnails {
  padding: 32px 24px;
  max-width: 800px;
  margin: auto;
}
[data-theme='dark'] {
  .trc_rbox_header_span {
    color: #e5e7eb;
  }
  .thumbnails-a .syndicatedItem .video-title {
    color: #b4bed2;
  }
  .videoCube .video-cta-href button.video-cta-button {
    border-color: #999999;
    color: #999999;
  }
}