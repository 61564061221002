.loading {
  padding: 32px 24px;
  [data-theme='dark'] & {
    background-color: #1F1F1F;
  }
}

.wrap {
  background-color: #FFFFFF;
  [data-theme='dark'] & {
    background-color: #303030;
  }
}

.ai-summary-container {
  padding: 32px 24px;
  max-width: 800px;
  margin: auto;
  .ai-summary-google-small-ads {
      text-align: center;
      width: 100%;
      min-width: 320px;
      /* height: 50px; */
      margin-bottom: 24px;
  }

  .ai-summary-google-large-ads {
      text-align: center;
      width: 100%;
      min-width: 300px;
      /* height: 250px; */
      margin-top: 24px;
      margin-bottom: 24px;
  }

  header {
    .rank {
      font-size: 18px;
      color: #4403B5;
      margin-bottom: 8px;
      font-weight: 700;
      display: inline-block;
      line-height: 21px;
    }
    .keyword {
      font-size: 32px;
      color: #4403B5;
      margin-bottom: 16px;
      font-weight: 700;
      line-height: 38px;
    }
  }

  .thumbnail-layout {
    flex-shrink: 0;
    width: 100%;
    grid-gap: 10px;
    margin-bottom: 16px;
    .thumbnail {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: 8px;
      width: 100%;
      img {
        width: 100%;
        border-radius: 8px;
        object-fit: cover;
        aspect-ratio: 180 / 120;
      }
    }
  }

  .ai-news {
    h3 {
      font-size: 24px;
      font-weight: 700;
      line-height: 28.64px;
      text-align: left;
      margin-bottom: 16px;
      color: #2E3338;
      display: flex;
      align-items: center;
      gap: 4px;
    }
    .ai-news-content {
      font-size: 16px;
      line-height: 24px;
      p:nth-child(1) strong {
        color: #57616B;
        font-weight: 700;
        line-height: 21.48px;
        text-align: left;
        margin-bottom: 8px;
      }
        // color: #57616B;
    }
  }

  .news-summary {
    h3 {
      color: #2E3338;
      font-size: 24px;
      font-weight: 700;
      line-height: 28.64px;
      text-align: left;
      margin-bottom: 16px;
      display: flex;
      align-items: center;
      gap: 4px;
    }
    .news-content {
      &.with-line {
        padding-left: 16px;
        border-left: 1px solid #89949F;
      }
      .news-text-container {
        h4 {
          margin-bottom: 0;
          padding-block: 4px;
        }
      }
      &:nth-child(1) {
        .news-text-container {
          h4 {
            margin-bottom: 16px;
          }
        }
      }
      .news-text-container {
        h4 {
          display: flex;
          align-items: center;
          justify-content: space-between;
          a {
            color: #2E3338;
            font-size: 18px;
            font-weight: 700;
            line-height: 27px;
            text-align: left;
            b {
              font-weight: 700;
            }
          }
          .time-ago {
            font-size: 14px;
            font-weight: 500;
            line-height: 21px;
            text-align: left;
            flex-shrink: 0;
            margin-left: 8px;
          }
        }
      }
    }
  }

  // 다크모드
  [data-theme='dark'] & {
    background-color: #303030;
    
    .rank {
      color: #B196FF;
    }

    .keyword {
      color: #B196FF;
    }

    .ai-news {
      h3 {
        color: #E5E7EB;
      }
      .ai-news-content {
        p:nth-child(1) strong {
          color: #D1D5DB;
        }
        p:nth-child(2) {
          // color: #D1D5DB;
        }
      }
    }

    .news-summary {
      h3 {
        color: #E5E7EB;
      }
      .news-content {
        &.with-line {
          border-left-color: #4A4A4A;
        }
        
        ul {
          color: #9CA3AF;
          
          li {
            &::before {
              background-color: #B196FF;
            }
            
            strong {
              color: #E5E7EB;
            }
            
            a {
              color: #B196FF;
              
              &:hover {
                color: #CAB3FF;
              }
            }
          }
        }

        .news-text-container {
          h4 {
            a {
              color: #E5E7EB;
              
              &:hover {
                color: #B196FF;
              }
            }
            .time-ago {
              color: #D1D5DB;
            }
          }
        }
      }
    }

    .summary {
      ul {
        color: #9CA3AF;
        
        li {
          strong {
            color: #E5E7EB;
          }
          
          a {
            color: #B196FF;
            
            &:hover {
              color: #CAB3FF;
            }
          }
        }
      }
    }
  }
}